<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-sheet color="grey lighten-3" class="pa-2">
        <v-avatar class="mb-4 ml-12" color="grey darken-4" size="84">
          <img :src="url" :alt="usuario.nombre" />
        </v-avatar>
        
        <div v-text="usuario.username" class="teal--text text-h8 text-center mr-10"></div>
        <!-- <div v-text="usuario.email" class="teal--text text-h8 text-center mr-10"></div> -->
        <div v-text="usuario.roleName" class="teal--text text-h8 text-center mr-10"></div>
      </v-sheet>
      <v-divider></v-divider>
      <v-list dense nav>
        <template v-for="item in menusAndModules">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
          </v-row>
          <v-list-group v-else-if="item.children"
            v-model="item.model" :key="item.text"
            :prepend-icon="item.model ? 'fas fa-caret-down ' : 'fas fa-caret-right'"
            append-icon="">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"> {{ item.text }} </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item link v-for="(child, i) in item.children" :key="i"
            :to="child.to" class="lista">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                  <template v-if="child.new">
                    <v-icon right color="pink pink-3" small>fas fa-fire</v-icon>
                    <span class="pink--text text--lighten-1 font-weight-medium" color="pink pink-3">New</span>
                  </template>
                  <template v-if="child.update">
                    <v-icon right color="green green-3" small>fas fa-fire</v-icon>
                    <span class="green--text text--lighten-2 font-weight-bold" color="green green-3"></span>
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.text + '1'" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
                <v-chip
                  v-if="item.new"
                  class="ma-2"
                  color="pink"
                  label
                  text-color="white"
                >
                  <v-icon left>fas fa-new</v-icon> New
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <AppBar :title="nameSucursal" :toogledrawer.sync="toogleDrawer">
    </AppBar>
    <v-main>
      <router-view />
      <v-container fluid></v-container>
    </v-main>
    <!-- footer in vuetify-->
    <Footer :footer="'Sistema de ventas para la ' + nameSucursal " />
  </v-app>
</template>

<script>
import UsuarioDataService from "@/service/usuario/usuarioDataService";
import SucursalDataService from "@/service/catalogo/sucursal/sucursalDataService";
import Footer from "@/components/shared/Footer.vue";
import imageProfile from "@/assets/user.png";
import AppBar from "@/components/shared/AppBar.vue";
import createPermissions from "@/service/permiso/Permisos";

export default {
  name: "principal-layout",
  components: { Footer, AppBar },
  created() {
    this.getUser();
  },
  data: () => ({
    dialog: false,
    drawer: null,
    usuario: [],
    url: imageProfile,
    menusAndModules: []
  }),
  methods: {
    toogleDrawer() {
      this.drawer = !this.drawer;
    },
    getUser() {
      const storeAuth = this.$store.state.auth;
      UsuarioDataService.getProfile(storeAuth.user.id)
        .then(response => {
          if (response.status == 200) {
            const { data: row, data: { roleName: roles, imagen } } = response;
            this.$store.dispatch("auth/setUser", row);
            this.usuario = row;
            this.menusAndModules = createPermissions(roles);
            if (imagen != null && imagen.length > 0) {
              this.url = `${storeAuth.rutaFichero}avatars/${imagen}`;
            }
          }
        })
        .catch(error => {
          this.$swal.fire({
            position: "top-end",
            icon: "error",
            text: error,
            title: "Error al obtener el perfil",
            showConfirmButton: false,
            timer: 1500
          });
        });
    }
  },
  computed: {
    nameSucursal() {
      return SucursalDataService.getCurrentSucursal()?.nombre || "";
    }
  }
};
</script>
<style>
  .lista {
    margin-left: 15px;
  }
</style>
