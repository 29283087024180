const commonItems = {
    inicio: { icon: 'fas fa-home', text: 'Inicio', to:'/controlPanel' },
    catalogos: {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: 'Catalogos',
        model: false,
        children: [
            { text: 'Empresas', icon: 'fas fa-building', to: '/controlPanel/empresas' },
            { text: 'Sucursales', icon: 'fas fa-building', to: '/controlPanel/sucursales' },
        ],
    },
    productos: {
        text: 'Productos',
        model: false,
        children: [
            { text: 'Productos', icon: 'fas fa-th-list', to: '/controlPanel/productos' },
            { text: 'Listas de Precios', icon: 'fas fa-list-ol', to: '/controlPanel/listaPrecio' },
        ]
    },
    entradas: {
        text: 'Entradas',
        model: false,
        children: [
            { text: 'Entradas', icon: 'fas fa-cart-arrow-down', to: '/controlPanel/entradas' },
            { text: 'Alta proveedor', icon: 'fas fa-user-friends', to: '/controlPanel/proveedores' },
            { text: 'Pagos proveedores', icon: 'fas fa-dollar-sign', to: '/controlPanel/pagosProveedores' },
        ]
    },
    ventas: {
        text: 'Ventas',
        model: true,
        children: [
            { text: 'Apertura caja', icon: 'fas fa-cash-register', to: '/controlPanel/cashRegister', new: false, update: true },
            { text: 'Ventas', icon: 'fas fa-cart-arrow-down', to: '/controlPanel/ventas', new: false, update: false },
            { text: 'Pagos', icon: 'fas fa-dollar-sign', to: '/controlPanel/pagos' },
            { text: 'Clientes', icon: 'fas fa-id-card', to: '/controlPanel/clientes' },
        ]
    },
    personal: {
        text: 'Personal',
        model: false,
        children: [
            { text: 'Puestos', icon: 'fas fa-briefcase', to: '/controlPanel/puestos' },
            { text: 'Empleados', icon: 'fas fa-user-tie', to: '/controlPanel/empleados' },
            { text: 'Departamentos', icon: 'fas fa-building', to: '/controlPanel/departamentos' },
            { text: 'Asistencias', icon: 'fas fa-user-clock', to: '/controlPanel/asistencias' },
            { text: 'Lista de raya', icon: 'fas fa-list', to: '/controlPanel/listaRaya' },
            // { text: 'Calendario',    icon: 'fas fa-calendar-alt', to: '/controlPanel/calendario' },
            { text: 'Deducciones',   icon: 'fas fa-wallet', to: '/controlPanel/deducciones' },
        ]
    },
    perfil: {
        text: 'Perfil',
        model: false,
        children: [
            { text: 'Usuarios', icon: 'fas fa-user-shield', to: '/controlPanel/usuarios' },
        ]
    },
    reportes: {
        text: 'Reportes',
        model: false,
        children: [
            { text: 'Reportes', icon: 'fas fa-tasks', to: '/controlPanel/reportes' },
        ]
    }
};

const createPermissions = (roles) => {
    const permissions = {
        ROLE_ADMIN: [
            commonItems.inicio,
            commonItems.catalogos,
            commonItems.productos,
            commonItems.entradas,
            commonItems.ventas,
            commonItems.personal,
            commonItems.perfil,
            commonItems.reportes,
        ],
        ROLE_VENDEDOR: [
            commonItems.inicio,
            {
                text: 'Entradas',
                model: false,
                children: [
                    commonItems.entradas.children[0],
                    commonItems.entradas.children[1],
                ]
            },
            {
                text: 'Ventas',
                model: false,
                children: [
                    commonItems.ventas.children[0],
                    commonItems.ventas.children[1],
                ]
            },
        ],
        ROLE_USER: [
            commonItems.inicio,
        ],
        ROLE_COBRANZA: [
            commonItems.inicio,
            {
                text: 'Proveedores',
                model: false,
                children: [
                    commonItems.entradas.children[1],
                    commonItems.entradas.children[2]
                ]
            },
            {
                text: 'Ventas',
                model: false,
                children: [
                    commonItems.ventas.children[2],
                    commonItems.ventas.children[3]
                ]
            }
        ],
        ROLE_ADMINISTRATOR_1: [
            commonItems.inicio,
            commonItems.entradas,
            {
                text: 'Ventas',
                model: false,
                children: [
                    commonItems.ventas.children[1],
                    commonItems.ventas.children[2],
                    commonItems.ventas.children[3],
                ]
            },
            commonItems.personal
        ],
        ROLE_ADMINISTRATOR_2: [
            commonItems.productos,
            commonItems.entradas,
            {
                text: 'Ventas',
                model: false,
                children: [
                    commonItems.ventas.children[1],
                    commonItems.ventas.children[2],
                    commonItems.ventas.children[3],
                ]
            },
            commonItems.reportes,
        ],
    };

    return roles && permissions[roles] ? permissions[roles] : permissions.ROLE_USER;
};
export default createPermissions
